import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderSevice } from '../../api/OrderService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICart } from '../../interfaces/Cart'
import { cartActions } from '../../store/reducers/cart'
import { AppDispatch } from '../../store/store'
import { createId } from '../../utils/createId'
import { prepareOrder } from '../../utils/prepareProduct'
import './Promocode.scss'

interface IError {
    [key: string]: string
}
const Errors: IError = {
    'total order cost should be more minimal cost.':
        'Минимальная сумма для данного промокода: ',
    // eslint-disable-next-line quotes
    "promocode didn't start or was expired":
        'В данный момент промокод не действует',
    'Promocode not found': 'Промокод не найден',
}

export const Promocode = () => {
    const [code, setCode] = useState('')
    const [error, setError] = useState('')
    const btnRef = useRef<HTMLButtonElement>(null)
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value)
    }
    useEffect(() => {
        let hasPromo = false
        cart.forEach((c) => {
            if (c.isPromocode) hasPromo = true
        })
        if (!hasPromo && btnRef.current) {
            btnRef.current.disabled = false
            btnRef.current.style.cursor = 'pointer'
            btnRef.current.style.backgroundColor = '#004D53'
        }
    }, [cart])
    const dispatch = useDispatch<AppDispatch>()
    const checkPromo = async () => {
        try {
            if (btnRef.current) btnRef.current.disabled = true
            setError('')
            const _products = prepareOrder(cart, products)
            const api = new OrderSevice()
            const res = await api.checkPromocode(
                code,
                currentConcept!._id,
                _products
            )
            console.log(res)
            if (res.product) {
                // const _product = products.find((p) => p.code === res.product)
                // if (_product) {
                console.log(res.product)

                const _cart: ICart = {
                    count: 1,
                    product: res.product,
                    modifiers: [],
                    id: createId(res.product._id, []),
                    isPromocode: true,
                    price: res.product.price,
                    name: res.product.name,
                }
                dispatch(cartActions.addToCart(_cart))
                dispatch(cartActions.setAppliedPromocode(code))
                setCode('')
                btnRef.current!.style.backgroundColor = 'gray'
                btnRef.current!.style.cursor = 'not-allowed'
                setError('Промокод активирован')
                setTimeout(() => {
                    setError('')
                }, 2000)
                // }
            }
            if (res.err) {
                if (res.minSum) setError(`${Errors[res.err]}${res.minSum}₽`)
                else setError(`${Errors[res.err]}`)
                if (btnRef.current) {
                    btnRef.current.disabled = false
                }
            }
        } catch (e: any) {
            setError(
                `${Errors[e.message] || 'Неизвестная ошибка, попробуйте позже'}`
            )
            if (e.message) {
                const msg = e.message.split(' minSum: ')
                console.log(msg)

                if (msg.length > 1) setError(`${Errors[msg[0]]}${msg[1]}₽`)
                else setError(`${Errors[e.message] || ''}`)
                if (btnRef.current) {
                    btnRef.current.disabled = false
                    btnRef.current.dataset.loading = 'false'
                }
            }
            if (btnRef.current) {
                btnRef.current.disabled = false
            }
        }
    }

    return (
        <div className='promocode'>
            <h2 className='promocode__label'>Промокод</h2>
            <div className='promocode__inputs'>
                <input
                    className='promocode__inputs--input'
                    value={code}
                    onChange={onCodeChange}
                    type='text'
                />
                <button
                    ref={btnRef}
                    className='promocode__inputs--button'
                    onClick={checkPromo}>
                    Применить
                </button>
            </div>

            {error && <p className='promocode__error'>{error}</p>}
        </div>
    )
}
