import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { orderActions } from '../../store/reducers/order'
import { AppDispatch } from '../../store/store'
import { getTotal } from '../../utils/getTotal'
import Addresses from '../../utils/adresses.json'
export const OrderAddresses = () => {
    const { order, orderCheck } = useTypedSelector(
        (state) => state.orderReducer
    )
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)

    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        dispatch(
            orderActions.changeState({
                ...order,
                terminalId: null,
                address: {
                    ...order.address,
                    city: 'Красноярск',
                    flat: 0,
                    entrance: 0,
                    floor: 0,
                },
            })
        )
        dispatch(orderActions.clearOrderCheck())
    }, [])
    const { checkDelivery } = useActions()
    useEffect(() => {
        // eslint-disable-next-line no-undef
        let interval: string | number | NodeJS.Timeout | undefined

        if (order.address) {
            const { city, street, home } = order.address
            if (
                street &&
                street.trim().length > 0 &&
                home &&
                home.trim().length > 0
            ) {
                interval = setTimeout(() => {
                    checkDelivery({
                        city: city!,
                        street,
                        home,
                        conceptId: currentConcept?._id || '',
                    })
                }, 1000)
            }
        }

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [order.address?.street, order.address?.home])

    const streetsRef = useRef<HTMLInputElement>(null)
    const streetsListRef = useRef<HTMLDivElement>(null)
    const onChangeStreet = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e
        streetsListRef.current!.innerHTML = ''
        dispatch(
            orderActions.changeState({
                ...order,
                address: {
                    ...order.address,
                    street: e.target.value,
                },
            })
        )
        if (value.length > 2) {
            const _address = Addresses.find(
                (address) => address.city.name === order.address?.city
            )
            const matchedStreets = _address?.streets.filter((street) =>
                street.name.toLowerCase().includes(value.toLowerCase())
            )

            if (streetsListRef.current) {
                matchedStreets?.map((str) => {
                    const p = document.createElement('p')
                    p.classList.add('suggest-address')
                    p.innerText = str.name
                    p.onclick = () => {
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                address: {
                                    ...order.address,
                                    street: str.name,
                                },
                            })
                        )
                        streetsListRef.current!.textContent = ''
                    }
                    streetsListRef.current!.append(p)
                    return str
                })
            }
        }
    }

    return (
        <div className='order-address'>
            <h1 className='order-address__header'>Адрес доставки</h1>
            <span>г. {order.address?.city}</span>
            <div className='order-address__inputs'>
                <input
                    className='order-address__inputs-input --street'
                    value={order.address?.street || ''}
                    placeholder='Улица'
                    onChange={onChangeStreet}
                    ref={streetsRef}
                />
                <input
                    className='order-address__inputs-input --home'
                    value={order.address?.home || ''}
                    placeholder='Дом'
                    onChange={(e) =>
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                address: {
                                    ...order.address,
                                    home: e.target.value,
                                },
                            })
                        )
                    }
                />
                <input
                    className='order-address__inputs-input --flat'
                    value={order.address?.flat || ''}
                    placeholder='Кв.'
                    onChange={(e) =>
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                address: {
                                    ...order.address,
                                    flat: +e.target.value,
                                },
                            })
                        )
                    }
                />
                <input
                    className='order-address__inputs-input --floor'
                    value={order.address?.floor || ''}
                    placeholder='Этаж'
                    onChange={(e) =>
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                address: {
                                    ...order.address,
                                    floor: +e.target.value,
                                },
                            })
                        )
                    }
                />
                <input
                    className='order-address__inputs-input --entrance'
                    value={order.address?.entrance || ''}
                    placeholder='Подъезд'
                    onChange={(e) => {
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                address: {
                                    ...order.address,
                                    entrance: +e.target.value,
                                },
                            })
                        )
                    }}
                />
                <div
                    className='order-address__inputs-input-streets-list'
                    ref={streetsListRef}></div>
            </div>
            {orderCheck && (
                <div className='order-address__delivery-check'>
                    {orderCheck.areaName ? (
                        <p>
                            Ваш адрес находится в бесплатной зоне доставки для
                            заказов от {orderCheck.minOrderSum}₽
                            <br />
                            {orderCheck.minOrderSum! -
                                getTotal(products, cart) >
                                0 && (
                                <>
                                    <strong>
                                        До бесплатной доставки ещё:{' '}
                                        {orderCheck.minOrderSum! -
                                            getTotal(products, cart)}
                                        ₽
                                    </strong>
                                    <br />
                                    {orderCheck.deliveryPrice! > 0 && (
                                        <strong>
                                            Стоимость доставки:{' '}
                                            {orderCheck.deliveryPrice}₽
                                        </strong>
                                    )}
                                </>
                            )}
                        </p>
                    ) : (
                        <p>
                            На ваш адрес нет бесплатной доставки. С вами
                            свяжется оператор для уточнения деталей заказа.
                        </p>
                    )}
                </div>
            )}{' '}
        </div>
    )
}
