import React, { useEffect, useRef, useState } from 'react'
// import Background from '../../assets/background.jpg'
import AppleWallet from '../../assets/Add_to_Apple_Wallet_rgb_RU.svg'
import GooglePay from '../../assets/Save_to_Google_Pay.svg'
import './Profile.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { useDispatch } from 'react-redux'
import { customerActions } from '../../store/reducers/customer'
import { PersonalInfo } from './PersonalInfo'
import { Orders } from './Orders'
import { Addresses } from './Addresses'
import { MiniCart } from '../../components/miniCart/MiniCart'
import { Helmet } from 'react-helmet'
const NavTypes = {
    profile: 'Личные данные',
    orders: 'Заказы',
    addresses: 'Адреса',
}
export const Profile = () => {
    const navigate = useNavigate()
    const header = useRef<HTMLHeadingElement>(null)
    const { customer } = useTypedSelector((state) => state.customerReducer)
    const dispatch = useDispatch()
    const [activeType, setActiveType] = useState<
        'profile' | 'orders' | 'addresses'
    >('profile')
    useEffect(() => {
        if (!window.localStorage.getItem('baker_token')) {
            navigate('/signin')
        }
    }, [])

    const logout = () => {
        localStorage.removeItem('baker_token')
        dispatch(customerActions.clear())
        navigate('/')
    }

    const menuClickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
        const elems = document.querySelectorAll('li.profile__nav-item')
        const target = e.target as HTMLLIElement
        target.classList.add('--active')
        elems.forEach((elem) => {
            if (elem !== target) elem.classList.remove('--active')
        })
        const data = target.dataset.type as 'profile' | 'orders' | 'addresses'
        setActiveType(data)
        if (header.current) {
            header.current.innerText = NavTypes[data]
        }
    }
    if (!customer?._id) return <Placeholder />
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>Профиль</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <section className='profile'>
                <div className='profile__content'>
                    <h1 ref={header} className='profile__content-header'>
                        Личные данные
                    </h1>
                    {activeType === 'profile' && (
                        <PersonalInfo
                            fio={`${customer.firstName} ${customer.lastName}`}
                            phone={customer.phone}
                            dob={customer.dob}
                        />
                    )}
                    {activeType === 'orders' && <Orders />}
                    {activeType === 'addresses' && <Addresses />}
                </div>

                <ul className='profile__nav'>
                    <li
                        className='profile__nav-item --active'
                        data-type='profile'
                        onClick={menuClickHandler}>
                        Личные данные
                    </li>
                    <li
                        className='profile__nav-item'
                        data-type='orders'
                        onClick={menuClickHandler}>
                        Заказы
                    </li>
                    {/* <li
                    className='profile__nav-item'
                    data-type='addresses'
                    onClick={menuClickHandler}>
                    Адреса
                </li> */}
                    <li className='profile__nav-item' onClick={logout}>
                        Выход
                    </li>
                </ul>
                <MiniCart />
            </section>
        </>
    )
}
