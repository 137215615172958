import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import Placeholder from '../../assets/zag.png'
import ToTop from '../../assets/arrow-top.png'
import { useImage } from '../../hooks/useImage'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProduct } from '../../interfaces/Product'
import { getTotal } from '../../utils/getTotal'
import './MiniCart.scss'

export const MiniCart = () => {
    const [total, setTotal] = useState(0)
    const [lastProduct, setProduct] = useState<IProduct>()
    const [modifiers, setModifiers] = useState('')
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)

    const { imgSrc } = useImage(lastProduct?.image?.body || '', Placeholder)
    useEffect(() => {
        const { length } = cart
        if (length > 0) {
            const li = cart[length - 1]
            const liObj = products.find((i) => i._id === li.product._id)
            setProduct(liObj)

            setTotal(getTotal(products, cart))

            const modsArray = li.modifiers.map((group) => group.name)
            setModifiers(modsArray.join(', '))
        }
    }, [cart])

    return cart.length > 0 && lastProduct ? (
        isMobile ? (
            <div className='mini-cart-mobile'>
                <Link
                    to={`/${currentConcept!.slug}/cart`}
                    className='mini-cart-mobile__btn'>
                    Корзина <span>{total}₽</span>
                </Link>
                <button
                    className='mini-cart-mobile__to-top'
                    onClick={(e) => {
                        document.body.scrollIntoView({
                            behavior: 'smooth',
                        })
                    }}>
                    <img src={ToTop} alt='Наверх' />
                </button>
            </div>
        ) : (
            <div className='mini-cart'>
                <div className='mini-cart__content'>
                    <div className='mini-cart__content-item'>
                        <img src={imgSrc} alt={lastProduct.name} />
                        <div className='mini-cart__content-item--title'>
                            <p>{lastProduct.name}</p>
                            {/* <small>{lastProduct.name}</small> */}
                            <small>{modifiers}</small>
                        </div>
                    </div>
                    <div className='mini-cart__content-summary'>
                        <Link to={`/${currentConcept!.slug}/cart`}>
                            Корзина
                        </Link>
                        <p>{total}₽</p>
                    </div>
                </div>
            </div>
        )
    ) : null
}
