import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logobs.png'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { DeliveryAddressInput } from '../UI/deliveryAddressInput/DeliveryAddressInput'
import './Header.scss'
export const Header = ({ readOnly = false }) => {
    const [isHidden, setHidden] = useState(true)
    const ddStyles = classNames({
        'delivery-address-search__dropdown--back': true,
        open: !isHidden,
    })
    const { customer } = useTypedSelector((state) => state.customerReducer)
    return (
        <>
            <header className='header'>
                <div className='header__inner'>
                    <Link className='header__logo' to='/'>
                        <img className='header__logo' src={Logo} alt='logo' />
                    </Link>
                    {/* <DeliveryAddressInput
                    isHidden={isHidden}
                    setHidden={setHidden}
                /> */}
                    {!readOnly ? (
                        customer ? (
                            <Link className='header__username' to={'/profile'}>
                                {`${customer.firstName} ${customer.lastName[0]}.`}
                            </Link>
                        ) : (
                            <Link className='header__signin' to={'/signin'}>
                                Войти
                            </Link>
                        )
                    ) : null}
                </div>
            </header>
            <div className={ddStyles}></div>
        </>
    )
}
