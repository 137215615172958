import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { orderActions } from '../../store/reducers/order'
import { AppDispatch } from '../../store/store'
import { DateTime } from 'luxon'

export const PickTime = () => {
    const { order } = useTypedSelector((state) => state.orderReducer)
    const dispatch = useDispatch<AppDispatch>()
    const todayRef = useRef<HTMLSpanElement>(null)
    const tomorrowRef = useRef<HTMLSpanElement>(null)
    const [chosenDay, setDay] = useState(DateTime.now().toLocaleString())
    const chooseDay = (day: string) => {
        if (todayRef.current && tomorrowRef.current) {
            if (day === 'today') {
                todayRef.current.classList.add('--active')
                tomorrowRef.current.classList.remove('--active')
                setDay(DateTime.now().toLocaleString())
            } else {
                tomorrowRef.current.classList.add('--active')
                todayRef.current.classList.remove('--active')
                setDay(DateTime.now().plus({ day: 1 }).toLocaleString())
            }
        }
        const date = new Date()
        if (day === 'tomorrow') date.setDate(date.getDate() + 1)
        dispatch(
            orderActions.changeState({
                ...order,
                deliveryTime: DateTime.fromJSDate(date)
                    .setZone('Asia/Krasnoyarsk')
                    .toISO(),
            })
        )
    }
    const onTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        if (value.length === 5) {
            const date = new Date()
            date.setHours(+value.split(':')[0])
            date.setMinutes(+value.split(':')[1])

            const newDeliveryTime = `${order.deliveryTime?.split('T')[0]}T${
                date.toTimeString().split(' ')[0]
            }`

            dispatch(
                orderActions.changeState({
                    ...order,
                    deliveryTime: DateTime.fromISO(newDeliveryTime)
                        .setZone('Asia/Krasnoyarsk')
                        .toISO(),
                })
            )
        }
    }
    return (
        <div className='making-order__pick-time'>
            <h1 className='making-order__pick-time__header'>Выберите время</h1>
            <div className='making-order__pick-time-types'>
                <button
                    className={`making-order__pick-time-types--btn ${
                        order.deliveryTime === null ? '--active' : ''
                    }`}
                    onClick={() =>
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                deliveryTime: null,
                            })
                        )
                    }>
                    Ближайшее
                </button>
                <button
                    className={`making-order__pick-time-types--btn ${
                        order.deliveryTime !== null ? '--active' : ''
                    }`}
                    onClick={() =>
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                deliveryTime: DateTime.now()
                                    .setZone('Asia/Krasnoyarsk')
                                    .toISO(),
                            })
                        )
                    }>
                    Указать время
                </button>
            </div>
            {order.deliveryTime !== null && (
                <div className='making-order__pick-time-inputs'>
                    <div className='making-order__pick-time-inputs--date'>
                        <div className='making-order__pick-time-inputs--spans'>
                            <span
                                className='--active'
                                onClick={() => chooseDay('today')}
                                ref={todayRef}>
                                Сегодня
                            </span>
                            /
                            <span
                                onClick={() => chooseDay('tomorrow')}
                                ref={tomorrowRef}>
                                Завтра
                            </span>
                        </div>
                        <div className='chosen-date'>{chosenDay}</div>
                    </div>

                    <div className='making-order__pick-time-inputs--input-time'>
                        <label>Время</label>
                        <input
                            type={'time'}
                            value={
                                order.deliveryTime
                                    ? new Date(
                                          order.deliveryTime
                                      ).toLocaleTimeString('ru-RU', {
                                          timeStyle: 'short',
                                      })
                                    : ''
                            }
                            onChange={onTimeChange}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
