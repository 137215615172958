import { ICart, ICartModifiers } from '../interfaces/Cart'
import { IOrderProduct, IOrderProductModifier } from '../interfaces/Order'
import { IProduct } from '../interfaces/Product'

export const prepareOrder = (cart: ICart[], products: IProduct[]) => {
    const _products: IOrderProduct[] = cart.map((c) => ({
        code: c.product.code,
        amount: c.count,
        modifiers: prepareMods(c.modifiers)
    }))
    return _products
}

const prepareMods = (modifiers: ICartModifiers[]) => {
    let _modifiers:IOrderProductModifier[] = []
    modifiers.forEach((m) => {
        const mods = m.option.map(o => ({
            id: o._id,
            amount: 1
        }))
        _modifiers = [..._modifiers, ...mods]
    })
    return _modifiers
}