import React, { FC, useEffect, useRef, useState } from 'react'
import Background from '../../assets/background.png'
import Beta from '../../assets/Beta.png'
import DeliveryCar from '../../assets/car_white.png'
import DeliveryCarGreen from '../../assets/car_green.png'
import ViaLogo from '../../assets/ViaLogo.png'
import ViaLogoWhite from '../../assets/ViaLogoWhite.png'
import './DeliveryCard.scss'
import { useNavigate } from 'react-router-dom'
import { IConcept } from '../../interfaces/Concept'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { useImage } from '../../hooks/useImage'
import { conceptActions } from '../../store/reducers/concept'
interface DeliveryCardProps {
    concept: IConcept
}
export const DeliveryCard: FC<DeliveryCardProps> = ({ concept }) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const imgLogoRef = useRef<HTMLImageElement>(null)
    const navigator = useNavigate()
    const [logo, setLogo] = useState(ViaLogo)

    const onHover = () => {
        if (imgRef.current)
            imgRef.current.src =
                imgRef.current.src === DeliveryCarGreen ? DeliveryCar : DeliveryCarGreen

        if (concept._id === '66e2c3c404e242f4855f0150') {
            setLogo((prev) => (prev === ViaLogo ? ViaLogoWhite : ViaLogo))
        }
    }

    const { imgSrc } = useImage(concept.image?.body || '', Background)

    const dispatch = useDispatch<AppDispatch>()
    const navigate = () => {
        if (concept.additionalData?.Status === 'inactive') return
        dispatch(conceptActions.setCurrentConcept(concept))
        navigator(`/${concept.slug}/menu`)
    }
    return (
        <div
            className='delivery-card'
            onMouseOver={onHover}
            onMouseOut={onHover}
            onClick={navigate}>
            <div className='delivery-card__background'>
                <img src={imgSrc} alt='background' />
            </div>
            <h4 className='delivery-card__header'>{concept.name}</h4>
            <h2 className='delivery-card__description'>{concept.additionalData?.description}</h2>
            {concept.additionalData?.Status === 'inactive' && (
                <div className='delivery-card__placeholder'>
                    <p>{concept.additionalData?.Placeholder}</p>
                </div>
            )}
            <div className='delivery-card__info'>
                <div className='delivery-card__info--schedule'>
                    {concept.additionalData?.businessHours.map((s, i) => (
                        <span key={i} className='delivery-card__info--time'>
                            {s.days}: {s.hours}
                        </span>
                    ))}
                </div>

                <div className='delivery-card__info--price'>
                    <img src={DeliveryCarGreen} alt='Car' ref={imgRef} />
                    <span>от {concept.additionalData?.minDeliveryCost}₽</span>
                </div>
            </div>
            {concept._id === '66e2c3c404e242f4855f0150' ? (
                <div className={'delivery-card__beta delivery-card__beta-via'}>
                    <img ref={imgLogoRef} src={logo} alt='Via' />
                </div>
            ) : (
                <div className={'delivery-card__beta'}>
                    <img ref={imgLogoRef} src={Beta} alt='beta' />
                </div>
            )}
            <h4 className='delivery-card__address'>{concept.additionalData?.address}</h4>
            <h3 className='delivery-card__phone'>{concept.additionalData?.phone}</h3>
        </div>
    )
}
