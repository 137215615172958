import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import './Footer.scss'
import MobileScreen from '../../assets/mobile_screen.png'
import AS from '../../assets/appstore.svg'
import TG from '../../assets/telegram-2.png'
import VK from '../../assets/vk-3.png'
import CW from '../../assets/cw.png'
import GP from '../../assets/GP.png'

const ADDRESSES = [
    {
        address: 'Мартынова, 12 (Baker Street)',
        phone: 'тел. 270-27-07',
        hrefPhone: '83912702707',
    },

    {
        address: 'Капитанская, 14 (Via Veneto)',
        phone: 'тел. 217-84-12',
        hrefPhone: '83912178412',
    },
]

export const Footer = () => {
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const location = useLocation()
    const paths = ['cart', 'order']

    return (
        <footer
            className={`footer${
                cart.length > 0 &&
                !paths.includes(location.pathname.split('/')[2]) &&
                location.pathname.split('/')[1] !== 'thanks-for-order' &&
                location.pathname.split('/')[1] !== 'order'
                    ? ' --has-cart'
                    : ''
            }`}>
            <div className='footer__inner'>
                <div className='footer__links'>
                    <div className='footer__addresses'>
                        <h2>Адреса</h2>
                        {ADDRESSES.map((addr) => (
                            <div key={addr.address}>
                                <span>{addr.address}</span>
                                <a href={`tel:${addr.hrefPhone}`}>{addr.phone}</a>
                            </div>
                        ))}
                    </div>
                    <div className='footer__docs'>
                        <Link className='footer__link' to={'/delivery'}>
                            Доставка и оплата
                        </Link>
                        <Link className='footer__link' to={'/agreement'}>
                            Политика конфиденциальности
                        </Link>
                    </div>
                </div>
                <div className='footer__app'>
                    <img src={MobileScreen} alt='mobile' className='footer__app-mobile' />
                    <div className='footer__app-mobile-links'>
                        <div className='mobile-links-header'>
                            Любимый ресторан у вас в телефоне!
                        </div>
                        <a
                            href='https://play.google.com/store/apps/details?id=one.clockwork.bakkerstreet'
                            target={'_blank'}>
                            <img src={GP} alt='googleplay' />
                        </a>
                        <a
                            href='https://apps.apple.com/ru/app/baker-street/id1560278266'
                            target={'_blank'}>
                            <img src={AS} alt='appstore' />
                        </a>
                    </div>
                </div>
            </div>
            <div className='footer__cw'>
                <div className='footer__cw-inner'>
                    <div className='footer__cw-socials'>
                        <a href='https://vk.com/baker_street_krsk' target={'_blank'}>
                            <img src={VK} alt='VK' />
                        </a>
                        <a href='https://t.me/baker_street_krsk' target={'_blank'}>
                            <img src={TG} alt='telegram' />
                        </a>
                    </div>
                    <div className='footer__cw-work-on'>
                        Работает на{' '}
                        <a href='https://cw.marketing/'>
                            <img src={CW} alt='CW' />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
