import React, { FC, useRef, useState } from 'react'
import { CustomerSevice } from '../../../api/CustomerService'
import { CodeInput } from '../codeInput/CodeInput'
import './PhoneInput.scss'

interface PhoneInputProps {
    setSuccessCode: (phone: string) => void
    setIsRegistered: (isRegistered: boolean) => void
}
export const PhoneInput: FC<PhoneInputProps> = ({
    setSuccessCode,
    setIsRegistered,
}) => {
    const [phone, setPhone] = useState('')
    const [isCode, setIsCode] = useState(false)
    const getInputNumbersValue = (inputValue: string) => {
        return inputValue.replace(/\D/g, '')
    }
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ruCodes = ['7', '8', '9']
        let input = e.target,
            inputNumberValue = getInputNumbersValue(input.value),
            selectionStart = input.selectionStart,
            formattedValue = ''

        if (!inputNumberValue) {
            return setPhone('')
        }
        if (input.value.length !== selectionStart) {
            const { data } = e.nativeEvent as InputEvent

            if (data && /\D/g.test(data)) {
                return setPhone(inputNumberValue)
            }
            console.log(input.value.length)

            if (input.value.length !== 11) return setPhone(input.value)
        }
        if (ruCodes.indexOf(inputNumberValue[0]) > -1) {
            if (inputNumberValue[0] === '9')
                inputNumberValue = `7${inputNumberValue}`
            const firstSymbols = inputNumberValue[0] === '8' ? '8' : '+7'
            formattedValue = `${firstSymbols} `
            if (inputNumberValue.length > 1)
                formattedValue += `(${inputNumberValue.substring(1, 4)}`
            if (inputNumberValue.length >= 5)
                formattedValue += `) ${inputNumberValue.substring(4, 7)}`
            if (inputNumberValue.length >= 8)
                formattedValue += `-${inputNumberValue.substring(7, 9)}`
            if (inputNumberValue.length >= 10)
                formattedValue += `-${inputNumberValue.substring(9, 11)}`
        } else formattedValue = `+${inputNumberValue.substring(0, 16)}`
        setPhone(formattedValue)
    }
    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value.replace(
            /\D/g,
            ''
        )
        if (e.key === 'Backspace' && inputValue.length === 1) setPhone('')
    }
    const btnRef = useRef<HTMLButtonElement>(null)
    const errorRef = useRef<HTMLParagraphElement>(null)
    const getSmsCode = async () => {
        try {
            if (errorRef.current) errorRef.current.innerHTML = ''
            setSuccessCode(phone)
            setIsCode(true)
            if (btnRef.current) {
                btnRef.current.disabled = true
                document.body.style.cursor = 'wait'
            }
            const api = new CustomerSevice()
            const _phone = phone
                .replaceAll(' ', '')
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('-', '')
                .replaceAll('+', '')
                .slice(1)

            const code = await api.sms(+_phone)

            // alert(code.message)
        } catch (error) {
            console.error(error)
            if (errorRef.current)
                errorRef.current.innerHTML =
                    'Произошла ошибка, попробуйте позже'
        } finally {
            if (btnRef.current) btnRef.current.disabled = false
            document.body.style.cursor = 'default'
        }
    }
    if (isCode)
        return (
            <CodeInput
                phone={phone}
                setIsRegistered={setIsRegistered}
                changePhone={() => {
                    setIsCode(false)
                    setPhone('')
                    setSuccessCode('')
                }}
            />
        )
    return (
        <>
            <div className='phone-input'>
                <input
                    className='phone-input__input'
                    type='tel'
                    maxLength={18}
                    value={phone}
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDownHandler}
                    placeholder='+7 (000) 000-00-00'
                />
                <button
                    className='phone-input__submit'
                    ref={btnRef}
                    onClick={getSmsCode}>
                    Выслать код
                </button>
            </div>
            <p className='phone-input-error' ref={errorRef}></p>
        </>
    )
}
