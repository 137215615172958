export const getWeight = (min: number, full: number, unit: string) => {
    const minWeight = min > 0 ? min : full
    const measure = {
        'кг': 'г',
        'л': 'мл'
    }
    const w = minWeight < 1 ? Math.floor(minWeight * 1000) : minWeight
    let postfix = ''
    if(unit === 'кг' || unit === 'л')
        postfix = minWeight < 1 ? measure[unit] : unit
    else postfix = unit
    return `${w}${postfix}`
}