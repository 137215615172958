import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { OrderSevice } from '../../api/OrderService'
import { CurrentConceptCard } from '../../components/currentConceptCard/CurrentConceptCard'
import { CustomerInfo } from '../../components/customerInfo/CustomerInfo'
import { MakingOrder } from '../../components/makingOrder/MakingOrder'
import { OrderCard } from '../../components/orderCard/OrderCard'
import { OrderPhone } from '../../components/orderPhone/OrderPhone'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICart } from '../../interfaces/Cart'
import { IOrder, IOrderProduct } from '../../interfaces/Order'
import { cartActions } from '../../store/reducers/cart'
import { orderActions } from '../../store/reducers/order'
import { AppDispatch } from '../../store/store'
import { prepareOrder } from '../../utils/prepareProduct'
import './Order.scss'
import { v4 as uuidv4 } from 'uuid'
import { Helmet } from 'react-helmet'

export const Order = () => {
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const { cart, cutlery, promocode } = useTypedSelector(
        (state) => state.cartReducer
    )
    const { products } = useTypedSelector((state) => state.productReducer)
    const { customer } = useTypedSelector((state) => state.customerReducer)
    const { order, orderCheck } = useTypedSelector(
        (state) => state.orderReducer
    )
    const dispatch = useDispatch<AppDispatch>()
    const [delTypes, setDelTypes] = useState<string[]>([])
    const submitRef = useRef<HTMLButtonElement>(null)
    const errorRef = useRef<HTMLParagraphElement>(null)
    const navigate = useNavigate()
    const submitOrder = async () => {
        submitRef.current!.disabled = true
        errorRef.current!.innerHTML = ''
        if (
            delTypes.findIndex((t) => t === order.deliveryTypeId) !== -1 &&
            !order.terminalId
        ) {
            alert('Укажите адрес самовывоза')
            submitRef.current!.disabled = false
            return false
        }

        if (delTypes.findIndex((t) => t === order.deliveryTypeId) === -1) {
            if (
                !order.address ||
                order.address?.street === '' ||
                order.address?.home === '' ||
                order.address?.entrance! < 0 ||
                order.address?.flat! < 0
            ) {
                console.log(order.address)

                alert('Укажите адрес доставки')
                submitRef.current!.disabled = false
                return false
            }
        }
        // if(order.deliveryTime)
        if (order.deliveryTime) {
            const time = new Date(order.deliveryTime)
            const minH = 12
            const maxH = 22
            if (time.getHours() < minH || time.getHours() >= maxH) {
                alert('Доставка осуществляется с 12:00 до 22:00')
                submitRef.current!.disabled = false
                return false
            }
        }

        const prod = prepareOrder(cart, products)
        try {
            const api = new OrderSevice()
            const res = await api.order({
                ...order,
                products: prod,
                personsCount: cutlery.amount,
            })
            console.log(res)

            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    purchase: {
                        actionField: {
                            id: uuidv4(),
                            coupon: promocode,
                            goal_id: 277357732,
                        },
                        products: prod.map((p) => {
                            return {
                                id: p.code,
                                name: p.name,
                                price: p.price,
                                quantity: p.amount,
                            }
                        }),
                    },
                },
            })

            navigate('/thanks-for-order')
        } catch (e) {
            console.error(e)
            errorRef.current!.innerHTML = 'Произошла ошибка'
        }
        submitRef.current!.disabled = false
    }

    if (!currentConcept || cart.length === 0) return <Navigate to={'/'} />
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>Оформление заказа</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='order-page'>
                <div className='order-page__header-wrapper'>
                    <h1 className='order-page__header'>Оформление заказа</h1>
                    {customer && <CustomerInfo />}
                </div>

                {!customer && (
                    <CurrentConceptCard currentConcept={currentConcept} />
                )}
                <div className='order-page__content'>
                    {customer ? (
                        <MakingOrder setDelTypes={setDelTypes} />
                    ) : (
                        <OrderPhone />
                    )}
                    <OrderCard />
                </div>
                {customer && (
                    <button
                        ref={submitRef}
                        // disabled={!orderCheck?.isInDeliveryArea}
                        className='order-page__submit'
                        onClick={submitOrder}>
                        Оформить
                    </button>
                )}
                <p ref={errorRef} className={'order-page__error'}></p>
            </div>
        </>
    )
}
