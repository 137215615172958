import React from 'react'
import { Helmet } from 'react-helmet'
import './Delivery.scss'
export const Delivery = () => {
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Baker Street доставка еды в Красноярске.
                    Завтраки, обеды и ужины с доставкой по всему городу, концерты, семейные торжества и корпоративные праздники!'
                />
                <title>Доставка</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='delivery'>
                <div className='delivery__col'>
                    <h3>КАК ЗАКАЗАТЬ? 🚀</h3>

                    <p>
                        <b>Через сайт:</b>
                        <br />
                        Добавьте блюда в корзину и оформите заказ, указав в
                        форме данные для доставки и оплаты.{' '}
                    </p>

                    <p>
                        <b>По телефону: </b>
                        <br />
                        Сделать заказ можно по телефону 270-27-08 с 12:00 до
                        22:00. В целях улучшения и контроля качества услуг все
                        разговоры с оператором записываются. Время доставки
                        может увеличиться в ситуации сильных пробок или другой
                        неблагоприятной обстановки на дорогах.
                    </p>

                    <p>
                        <b>МИНИМАЛЬНАЯ СУММА ЗАКАЗА - 500 рублей!</b>
                    </p>

                    <p>
                        Минимальная сумма заказа по правому берегу 500 рублей.
                        По левому берегу 700 рублей.В случае, если клиент
                        заказал что-то одно, но ему не хватает на минимальную
                        сумму, то он может воспользоваться платной доставкой 150
                        рублей
                    </p>
                </div>
                <div className='delivery__col'>
                    <h3>ОПЛАТА 💰</h3>

                    <p>
                        <b>Наличными курьеру</b>
                        <br />
                        Оплата наличными производится курьеру службы доставки
                        при получении заказа.
                    </p>

                    <p>
                        <b>Банковской картой курьеру</b>
                        <br />
                        Вы можете оплатить заказ банковской картой через
                        мобильный терминал при получении заказа. Мы принимаем
                        карты Visa, MasterCard и Maestro.
                    </p>
                </div>

                <div
                    className='delivery__map'
                    style={{ position: 'relative', overflow: 'hidden' }}>
                    <a
                        href='https://yandex.ru/maps/62/krasnoyarsk/?utm_medium=mapframe&utm_source=maps'
                        style={{
                            color: '#eee',
                            fontSize: '12px',
                            position: 'absolute',
                            top: '0px',
                        }}>
                        Красноярск
                    </a>
                    <a
                        href='https://yandex.ru/maps/62/krasnoyarsk/?ll=92.716941%2C56.015181&source=constructorLink&um=constructor%3Aaed59dee57cbcf43636c84aaeba14e29dc89e447834c868df29a06f4c197b5a8&utm_medium=mapframe&utm_source=maps&z=12.6'
                        style={{
                            color: '#eee',
                            fontSize: '12px',
                            position: 'absolute',
                            top: '14px',
                        }}>
                        Карта Красноярска с улицами и номерами домов онлайн —
                        Яндекс Карты
                    </a>
                    <iframe
                        src='https://yandex.ru/map-widget/v1/?um=constructor%3Aab1ee3307c26ed392614aac26e3eddbe12204a8c6da2d43693c9c5ffe0e8f94e&amp;source=constructor'
                        width='100%'
                        height='400'
                        frameBorder='0'></iframe>
                </div>
            </div>
        </>
    )
}
