import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { ICategory } from '../interfaces/Category'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class CategorySevice extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/categories'
    protected PATH_SMART = 'api/v1/smart_categories'
    getCategories = async(conceptId: string) => {
        try {
            const response = await axiosInstance.get<IApi<ICategory>>(`${this.URL}/${this.PATH}/?conceptId=${conceptId}&isDisabled=false&isDeleted=false&isHidden=false&limit=1000`)
            const responseSmart = await axiosInstance.get<IApi<ICategory>>(`${this.URL}/${this.PATH_SMART}/`, {
                params: {
                    conceptId,
                    isDisabled: false,
                    isDeleted: false, 
                    isHidden: false,
                    limit: 1000,
                    showInActive: true
                }
            })
            // return response.data
            return {
                ...response.data,
                data: [...response.data.data, ...responseSmart.data.data]
            }
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}