import React, { FC, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import Ad1 from '../../assets/ads1.png'
import Ad2 from '../../assets/ads2.png'
import Ad3 from '../../assets/ads3.png'
import Arrow from '../../assets/arrow.png'
import ArrowRight from '../../assets/arrow-right.png'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './BannersCarousel.scss'
import { useModal } from '../../hooks/useModal'
import { Modal } from '../UI/modal/Modal'
import { IContent } from '../../interfaces/Content'
import { useImage } from '../../hooks/useImage'

const NextArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} banners-carousel__arrow next`}
            style={style}
            onClick={onClick}>
            <img src={ArrowRight} alt='arrow' />
        </div>
    )
}
const PrevArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} banners-carousel__arrow prev`}
            style={style}
            onClick={onClick}>
            <img src={Arrow} alt='arrow' />
        </div>
    )
}

interface BannersCarouselProps {
    slides: IContent[]
    className?: string
    slideToShow?: number
    isMain?: boolean
}
export const BannersCarousel: FC<BannersCarouselProps> = ({
    slides,
    className = '',
    slideToShow = 3,
    isMain = false,
}) => {
    const { isShow, toggle } = useModal()
    const [activeBanner, setActive] = useState<IContent>()
    const openBanner = (banner: IContent) => {
        if (banner.text.trim().length === 0) return
        const _banner = slides.find((b) => b._id === banner._id)
        setActive(_banner!)
        toggle()
    }
    const settings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slides.length >= 4 ? 4 : 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 1500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: slides.length > 3 ? slideToShow : 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: slides.length > 3 ? slideToShow : 1,
                    slidesToScroll: 1,
                    // initialSlide: 0,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    }

    const mainResponsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
            },
        },
    ]
    return (
        <div className={`banners-carousel ${className}`}>
            <Slider
                {...settings}
                slidesToShow={
                    isMain ? 1 : slides.length > 3 ? slideToShow : slides.length
                }
                responsive={isMain ? mainResponsive : settings.responsive}
                infinite={slides.length >= slideToShow}
                autoplay={isMain}
                autoplaySpeed={15 * 1000}>
                {slides.map((slide) => {
                    return (
                        <div
                            className='banners-carousel__slide'
                            key={slide._id}
                            onClick={() => openBanner(slide)}>
                            <img src={slide.image} alt='ad' />
                        </div>
                    )
                })}
            </Slider>
            <Modal isShow={isShow} hide={toggle}>
                <div className='banner-modal'>
                    <header className='banner-modal__header'>
                        <img src={activeBanner?.image} alt='ad' />
                        <button
                            className='banner-modal__header--close'
                            onClick={toggle}>
                            &times;
                        </button>
                    </header>
                    <main className='banner-modal__content'>
                        <div className='banner-modal__content--text'>
                            <div>{activeBanner?.text}</div>
                        </div>
                        {activeBanner?.url && (
                            <a
                                target={'_blank'}
                                className='banner-modal__content--link'
                                href={activeBanner.url}>
                                Подробнее
                            </a>
                        )}
                    </main>
                </div>
            </Modal>
        </div>
    )
}
