import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IProduct } from '../interfaces/Product'
import { Api } from './Api'
import { axiosInstance } from './axios'

interface IFeatured {
    _id: string
    companyId: string
    conceptId: string
    products: IProduct[]
}

export class ProductService extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/products'
    protected PATH_SMART = 'api/v1/smart_products'
    getProducts = async(conceptId: string) => {
        try {
            const response = await axiosInstance.get<IApi<IProduct>>(`${this.URL}/${this.PATH}/?conceptId=${conceptId}&isDisabled=false&isDeleted=false&isHidden=false&limit=1000`)
            const responseSmart = await axiosInstance.get<IApi<IProduct>>(`${this.URL}/${this.PATH_SMART}/`, {
                params: {
                    conceptId,
                    isDisabled: false,
                    isDeleted: false, 
                    isHidden: false,
                    limit: 1000,
                    showInActive: true
                }
            })
            // return response.data
            return {
                ...response.data,
                data: [...response.data.data, ...responseSmart.data.data]
            }
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 402) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getFeaturedProducts = async(conceptId?: string) => {
        try {
            const response = await axiosInstance.get<IApi<IFeatured>>(`${this.URL}/api/v1/featured_products/`, {
                params: {
                    conceptId,
                    limit: 1000
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 402) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getProductByCode = async(code: string) => {
        try {
            const response = await axiosInstance.get<IProduct>(`${this.URL}/${this.PATH}/code/${code}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 402) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}