import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ym from 'react-yandex-metrika'
import { ICart, ICartModifiers } from '../../interfaces/Cart'
import { IProduct } from '../../interfaces/Product'
import { createId } from '../../utils/createId'

interface CartState {
    cart: ICart[],
    cutlery: {
        amount: number
    }
    promocode?: string,
}

const initialState: CartState = {
    cart: [],
    cutlery: {
        amount: 0
    }
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setAppliedPromocode: (state, action: PayloadAction<string>) => {
            state.promocode = action.payload
            ym('reachGoal', 'PROMOCODE', {code: action.payload})
        },
        addToCart: (state, action: PayloadAction<ICart>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => i.id === action.payload.id)
            if(itemsIndexInCartForAdd < 0) {
                state.cart = [...state.cart, action.payload]
            } else {
                state.cart = state.cart.map((c, i) => {
                    if(i === itemsIndexInCartForAdd) c.count++
                    return c
                })
            }

            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'add' : {
                        'products': [{
                            'id': action.payload.product,
                            'name': action.payload.name,
                            'price': action.payload.price,
                            'quantity': 1
                        }]
                    }
                }
            })

        },
        increaseCount: (state, action: PayloadAction<[IProduct, ICartModifiers[]]>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => i.id === createId(action.payload[0]._id, action.payload[1]))
            if(itemsIndexInCartForAdd !== -1) {
                state.cart = state.cart.map((c, i) => {
                    if(i === itemsIndexInCartForAdd) c.count++
                    return c
                })
            }

            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'add' : {
                        'products': [{
                            'id': action.payload[0]._id,
                            'name': action.payload[0].name,
                            'price': action.payload[0].price,
                            'quantity': 1
                        }]
                    }
                }
            })
        },
        decreaseCount: (state, action: PayloadAction<[IProduct, ICartModifiers[]]>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => i.id === createId(action.payload[0]._id, action.payload[1]))
            if(itemsIndexInCartForAdd !== -1) {
                state.cart = state.cart.map((c, i) => {
                    if(i === itemsIndexInCartForAdd) c.count--
                    return c
                }).filter(c => c.count !== 0)
            }

            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'remove' : {
                        'products': [{
                            'id': action.payload[0]._id,
                            'name': action.payload[0].name,
                            'price': action.payload[0].price,
                            'quantity': 1
                        }]
                    }
                }
            })
        },
        clear: (state) => {
            state.cart = []
        },
        increaseCutlery: (state) => {
            state.cutlery.amount += 1
        },
        decreaseCutlery: (state) => {
            state.cutlery.amount <= 1 ? state.cutlery.amount = 0 : state.cutlery.amount -= 1
        }
    }
})

export const cartReducer = cartSlice.reducer
export const cartActions = cartSlice.actions