import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IDeliveryCheck, IFeedback, IOrder, IOrderProduct } from '../interfaces/Order'
import { Api } from './Api'
import { axiosInstance } from './axios'
export interface OrderTypes {
    _id: string
    name: string
    conceptId: string
    companyId: string
    code: string
}
export class OrderSevice extends Api {
    constructor() {
        super()
    }

    checkPromocode = async(promocode: string, conceptId: string, products: IOrderProduct[]) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/promocodes/`, {
                promocode,
                conceptId,
                products
            })
            return response.data
        } catch (e) {
            interface AxiosResponseError {
                detail: string
            }
            const error = e as AxiosError<AxiosResponseError>
            if(error.response) { 
                throw new Error(error.response.data.detail)
            } else throw new Error(error.message)
        }
    }
    getOrders = async() => {
        try {
            const token = localStorage.getItem('baker_token')
            const response = await axiosInstance.get<IApi<IOrder>>(`${this.URL}/api/v1/orders/?limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getDeliveryTypes = async(conceptId: string) => {
        try {
            const response = await axiosInstance.get<OrderTypes[]>(`${this.URL}/api/v1/delivery_types/?conceptId=${conceptId}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getPaymentsTypes = async(conceptId: string) => {
        try {
            const response = await axiosInstance.get<OrderTypes[]>(`${this.URL}/api/v1/payments_types/?conceptId=${conceptId}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    order = async(customerOrder: IOrder) => {
        try {
            const token = localStorage.getItem('baker_token')
            // console.log(customerOrder)
            
            const response = await axiosInstance.post(`${this.URL}/api/v1/orders/order`, customerOrder, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data
            // return true
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }

    check = async(city: string, street: string, home: string, conceptId: string) => {
        try {
            const payload = {
                address: {
                    city,
                    street,
                    home
                },
                conceptId
            }
            const response = await axios.post<IDeliveryCheck>(`${this.URL}/api/v1/delivery/check/`, payload)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 500) throw new Error('Неверная пара логин\\пароль')
                if(error.response.status === 404) throw new Error('Not found')
                if(error.response.status === 422) throw new Error('Validation Error')
                else throw new Error('Сервер временно не работает')
            } else throw new Error('Сервер временно не работает')
        }
    }
    addFeedback = async(orderId: string, score: number, body: string, customerId: string) => {
        try {
            const token = localStorage.getItem('baker_token')
            const response = await axiosInstance.post(`${this.URL}/api/v1/feedbacks/`, {
                body,
                score,
                customerId,
                orderId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getFeedbackByOrder = async(orderId: string) => {
        try {
            const token = localStorage.getItem('baker_token')
            const response = await axiosInstance.get<IApi<IFeedback>>(`${this.URL}/api/v1/feedbacks/?orderId=${orderId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}