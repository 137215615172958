import axios, { AxiosError } from 'axios'
import { ICustomer } from '../interfaces/Customer'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class CustomerSevice extends Api {
    constructor() {
        super()
    }
    protected PATH_AUTH = 'api/v1/auth'
    protected PATH_ME = 'api/v1/me'
    sms = async(phone: number) => {
        const body = {
            phone, 
        }
        try {
            const response = await axiosInstance.post(`${this.URL}/${this.PATH_AUTH}/code`, body)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 402) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    login = async(phone: number, code: string) => {
        const body = {
            phone, 
            code,
        }
        try {
            const response = await axiosInstance.post(`${this.URL}/${this.PATH_AUTH}/token`, body)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 402) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    signup = async(firstName: string, lastName: string, email: string, dob: string, sex: 'male' | 'female') => {
        const body = {
            firstName, 
            lastName,
            email: email.trim().length !== 0  ? email : null,
            dob,
            sex
        }
        try {
            const token = localStorage.getItem('baker_token')
            const response = await axiosInstance.post(`${this.URL}/${this.PATH_AUTH}/signup`, body, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            
            if(error.response) {
                throw new Error(JSON.stringify(error.response.data))
                
            } else throw new Error(error.message)
        }
    }

    profile = async() => {
        try {
            const token = localStorage.getItem('baker_token')
            const response = await axiosInstance.get<ICustomer>(`${this.URL}/${this.PATH_ME}/profile`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 402) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}
