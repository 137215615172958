import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICustomer } from '../../interfaces/Customer'
import { getProfile } from '../actions/customer'

interface CustomerState {
    customer: ICustomer | null
}

const initialState: CustomerState = {
    customer: null
}

export const customerSlice = createSlice({
    initialState,
    name: 'customer',
    reducers: {
        clear: (state) => {
            state.customer = null
        }
    },
    extraReducers: {
        [getProfile.fulfilled.type]: (state, action: PayloadAction<ICustomer>) => {
            state.customer = action.payload
        },
        [getProfile.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.customer = null
            console.error(action.error.message)
            
        }
    }
})

export const customerReducer = customerSlice.reducer
export const customerActions = customerSlice.actions