import React, { FC, useRef, useState } from 'react'
import { CustomerSevice } from '../../../api/CustomerService'
import { useActions } from '../../../hooks/useActions'
import './CodeInput.scss'
interface CodeInputProps {
    changePhone: () => void
    phone: string
    setIsRegistered: (isRegistered: boolean) => void
}
export const CodeInput: FC<CodeInputProps> = ({
    changePhone,
    phone,
    setIsRegistered,
}) => {
    const btnRef = useRef<HTMLButtonElement>(null)
    const errorRef = useRef<HTMLParagraphElement>(null)
    const [code, setCode] = useState('')
    const { getProfile } = useActions()
    const submitCode = async () => {
        try {
            if (errorRef.current) errorRef.current.innerHTML = ''
            if (btnRef.current) {
                btnRef.current.disabled = true
                document.body.style.cursor = 'wait'
            }
            const api = new CustomerSevice()
            const _phone = phone
                .replaceAll(' ', '')
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('-', '')
                .replaceAll('+', '')
                .slice(1)

            const token = await api.login(+_phone, code)
            if (token.access_token)
                localStorage.setItem('baker_token', token.access_token)
            setIsRegistered(token.isRegistered)
            if (token.isRegistered) getProfile()
            console.log(token)
        } catch (error) {
            console.error(error)
            if (errorRef.current)
                errorRef.current.innerHTML =
                    'Произошла ошибка, попробуйте позже'
        } finally {
            if (btnRef.current) btnRef.current.disabled = false
            document.body.style.cursor = 'default'
        }
    }
    return (
        <>
            <div className='code-input'>
                <input
                    className='code-input__input'
                    placeholder='0000'
                    type={'number'}
                    maxLength={4}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <button
                    ref={btnRef}
                    className='code-input__submit'
                    onClick={submitCode}>
                    Подтвердить
                </button>
            </div>
            <p onClick={changePhone} className='code-input-change'>
                Изменить номер
            </p>
            <p className='code-input-error' ref={errorRef}></p>
        </>
    )
}
