import axios, { AxiosError } from 'axios'
import { IAddress } from '../interfaces/Address'
import { IApi } from '../interfaces/Api'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class AddressService extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/addresses'
    
    getAddresses = async() => {
        try {
            const token = localStorage.getItem('baker_token')
            const response = await axiosInstance.get<IApi<IAddress>>(`${this.URL}/${this.PATH}/?limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    removeAddress = async(id: string) => {
        try {
            const response = await axiosInstance.delete(`${this.URL}/${this.PATH}/${id}/?isDeleted=true`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}