import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useImage } from '../../hooks/useImage'
import { useModal } from '../../hooks/useModal'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICart } from '../../interfaces/Cart'
import { IProduct } from '../../interfaces/Product'
import { cartActions } from '../../store/reducers/cart'
import { AppDispatch } from '../../store/store'
import { createId } from '../../utils/createId'
import { getWeight } from '../../utils/getWeight'
import { Product } from '../../pages/product/Product'
import { Modal } from '../UI/modal/Modal'
import Placeholder from '../../assets/zag.png'
import './ProductCard.scss'
import { useNavigate } from 'react-router-dom'
import { useSaveCartToLocalStorage } from '../../hooks/useSaveCartToLocalStorage'
interface ProductCardProps {
    product: IProduct
    isCarousel?: boolean
    readOnly?: boolean
}

export const ProductCard: FC<ProductCardProps> = ({
    product,
    isCarousel = false,
    readOnly = false,
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { isShow, toggle } = useModal()
    const isSave = useSaveCartToLocalStorage()
    const addToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (!isSave) return alert('Чтобы продолжить необходимо очистить корзину')
        const target = e.target as HTMLButtonElement
        if (product.modifiers?.length > 0) {
            // toggle()
            navigate(`/${currentConcept?.slug}/product/${product.slug}`)
            return
        }
        const _cart: ICart = {
            count: 1,
            product,
            modifiers: [],
            id: createId(product._id, []),
            price: product.price,
            name: product.name,
        }
        dispatch(cartActions.addToCart(_cart))
        target.style.display = 'none'
        const sib = target.nextSibling as HTMLElement
        sib.style.display = 'flex'
    }

    const getCount = (id: string) => {
        const pInCart = cart.filter((p) => p.product._id === id)
        let totalCount = 0
        pInCart.forEach((p) => (totalCount += p.count))
        return totalCount
    }

    const { imgSrc } = useImage(product.image?.body || '', Placeholder)
    const navigate = useNavigate()
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    return (
        <>
            <div
                className={`product-card${isCarousel ? ' --carousel' : ''}`}
                onClick={() => {
                    navigate(`/${currentConcept?.slug}/product/${product.slug}`)
                }}>
                <div className='product-card__image'>
                    <div className='product-card__image--badges'>
                        {product.badges?.map((b) => (
                            <div key={b._id} className='product-card__image--badges-badge'>
                                <img
                                    className='product-card__image--badges-badge-img'
                                    src={b.image.body || ''}
                                    alt={b.name}
                                />
                                <div className='product-card__image--badges-badge-name'>
                                    {b.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    <img src={imgSrc} alt={product.name} />
                </div>
                <div className='product-card__content'>
                    <p className='product-card__content--header' title={product.name}>
                        {product.name}
                    </p>
                    {/* <p className='product-card__content--description'>
                        {product.description}
                    </p> */}
                    <div className='product-card__content-info'>
                        <div className='product-card__content-info--weight'>
                            {getWeight(
                                product.weight?.min || 0,
                                product.weight?.full || 0,
                                product.unit
                            )}
                        </div>
                        <div className='product-card__content-info--price'>{product.price}₽</div>
                        {!readOnly && (
                            <button
                                className='product-card__content-info--btn'
                                style={{
                                    display: getCount(product._id) > 0 ? 'none' : 'block',
                                }}
                                onClick={addToCart}>
                                Добавить
                            </button>
                        )}
                        <div
                            style={{
                                display: getCount(product._id) > 0 ? 'flex' : 'none',
                            }}
                            className='product-card__content-info--counter'>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    dispatch(cartActions.decreaseCount([product, []]))
                                }}>
                                -
                            </button>
                            <span>{getCount(product._id)}</span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    dispatch(cartActions.increaseCount([product, []]))
                                }}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modal isShow={isShow} hide={toggle}>
                <Product product={product} />
            </Modal> */}
        </>
    )
}
