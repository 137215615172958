import axios from 'axios'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async req => {
    if(req.headers) {
        req.headers['Company-Access-Key'] = 'zA8YqeCP6nxog80g15nKGlSz4VmZeWoThppQGOvvfrwEnVJm7X64lqnqjvlwcVz0'
        req.headers['Loyalaty-Id'] = '62fcbd5a6a95fef3bdefd488'
    }
    return req
})