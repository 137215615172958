import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IApiConcept, IConcept } from '../interfaces/Concept'
import { Api } from './Api'
import { axiosInstance } from './axios'
export class ConceptSevice extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/concepts'
    
    getConcepts = async() => {
        try {
            const response = await axiosInstance.get<IApi<IApiConcept>>(`${this.URL}/${this.PATH}/?isDisabled=false&isDeleted=false&isHidden=false&limit=1000`)
            const _concepts = response.data.data.map(c => ({
                ...c,
                additionalData: JSON.parse(c.additionalData)
            }))
            return {
                ...response.data,
                // data: _concepts
                data: [..._concepts]
            }
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getConceptById = async(id: string) => {
        try {
            const response = await axiosInstance.get<IApiConcept>(`${this.URL}/${this.PATH}/${id}`)
            const _concept = {
                ...response.data,
                additionalData: JSON.parse(response.data.additionalData)
            }
            return _concept
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}