import React, { FC, useState } from 'react'
import Slider from 'react-slick'
import Arrow from '../../assets/arrow.png'
import ArrowRight from '../../assets/arrow-right.png'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './ProductsCarousel.scss'

import { IProduct } from '../../interfaces/Product'
import { ProductCard } from '../productCard/ProductCard'

const NextArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} products-carousel__arrow next`}
            style={style}
            onClick={onClick}>
            <img src={ArrowRight} alt='arrow' />
        </div>
    )
}
const PrevArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} products-carousel__arrow prev`}
            style={style}
            onClick={onClick}>
            <img src={Arrow} alt='arrow' />
        </div>
    )
}

interface ProductsCarouselProps {
    products: IProduct[]
}
export const ProductsCarousel: FC<ProductsCarouselProps> = ({ products }) => {
    if (products.length === 0) return null

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: products.length > 2 ? 3 : products.length,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 1500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: products.length > 1 ? 2 : products.length,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    }

    return (
        <div className='products-carousel'>
            <p>Рекомендуем</p>
            <Slider {...settings}>
                {products.map((p) => (
                    <ProductCard key={p._id} product={p} isCarousel />
                ))}
            </Slider>
        </div>
    )
}
