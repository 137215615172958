import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './App.scss'
import { Footer } from './components/footer/Footer'
import { Header } from './components/header/Header'
import { useActions } from './hooks/useActions'
import { useSaveCartToLocalStorage } from './hooks/useSaveCartToLocalStorage'
import { useTypedSelector } from './hooks/useTypedSelector'
import { Agreement } from './pages/agreement/Agreement'
import { Cart } from './pages/cart/Cart'
import { Delivery } from './pages/delivery/Delivery'
import { Main } from './pages/main/Main'
import { Menu } from './pages/menu/Menu'
import { Order } from './pages/order/Order'
import { Product } from './pages/product/Product'
import { Profile } from './pages/profile/Profile'
import { SignIn } from './pages/signin/SignIn'
import { ThanksForOrder } from './pages/thanksForOrder/ThanksForOrder'
import { cartActions } from './store/reducers/cart'
import { AppDispatch } from './store/store'
import Helmet from 'react-helmet'
import { useModal } from './hooks/useModal'
import { Modal } from './components/UI/modal/Modal'
import Sleep from './assets/sleep.png'
import { conceptActions } from './store/reducers/concept'

export const getDomen = () => {
    const domen = window.location.hostname.split('.')[0]
    const availableDomens = ['br', 'ps', 'yb']
    if (availableDomens.includes(domen)) return domen
    return ''
}

function App() {
    const { currentConcept, concepts } = useTypedSelector((state) => state.conceptReducer)
    const { cart } = useTypedSelector((s) => s.cartReducer)
    const dispatch = useDispatch<AppDispatch>()
    const { getProfile, getConcepts, getContent } = useActions()
    const location = useLocation()
    const domen = getDomen()

    useEffect(() => {
        if (domen.length !== 0 && concepts.length > 0) {
            const concept = concepts.find((c) => c.slug === domen)
            dispatch(conceptActions.setCurrentConcept(concept!))
        }
    }, [concepts])

    useSaveCartToLocalStorage()
    useEffect(() => {
        console.log(JSON.stringify(cart))
    }, [cart])
    useEffect(() => {
        if (localStorage.getItem('baker_token')) getProfile()
        const slug = location.pathname.split('/')[1]
        getConcepts(slug)
        getContent()
    }, [])
    useEffect(() => {
        dispatch(cartActions.clear())
    }, [currentConcept?._id])

    const getTime = () => {
        //12:00 - 22:45 -- WORK TIME
        const minH = 12
        const minM = 0
        const maxH = 22
        const maxM = 0

        const startWork = new Date()
        startWork.setHours(minH, minM)

        const endWork = new Date()
        endWork.setHours(maxH, maxM)

        const now = new Date()

        return !(now >= startWork && now < endWork)
    }

    const { isShow, toggle } = useModal(domen.length === 0 ? getTime() : true)

    // const ref = useRef<HTMLDivElement>(null)

    // useEffect(() => {
    //     if (ref.current)
    //         ref.current.innerHTML =
    //             window.innerHeight + ' ' + window.outerHeight
    // }, [window.innerHeight, window.outerHeight])
    const nav = useNavigate()
    return (
        <>
            <Helmet titleTemplate='%s | Baker Street' />
            <Header readOnly={domen.length !== 0} />
            <Modal isShow={isShow} hide={() => (domen.length === 0 ? toggle() : {})}>
                <main className='modal-we-are-close'>
                    {domen.length === 0 && (
                        <header className='modal-we-are-close__header'>
                            <div onClick={toggle}>&#10006;</div>
                        </header>
                    )}
                    {domen.length === 0 ? (
                        <section className='modal-we-are-close__content'>
                            <div>
                                <img src={Sleep} alt='Кухня уже не работает' />
                            </div>

                            <h2>Кухня уже не работает</h2>
                            <p>В 12:00 мы будем рады принять ваш заказ</p>
                        </section>
                    ) : (
                        <section className='modal-we-are-close__content'>
                            <h1 style={{ fontSize: '2rem' }}>18+</h1>
                            <p>
                                Для доступа на сайт необходимо подтвердить свое совершеннолетие и
                                согласние на обработку файлов cookies.
                            </p>
                            <button
                                onClick={toggle}
                                style={{
                                    width: '100%',
                                    backgroundColor: 'black',
                                    color: 'white',
                                    textAlign: 'center',
                                    border: 'none',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                }}>
                                Подтверждаю
                            </button>
                        </section>
                    )}
                </main>
            </Modal>
            <main className='content'>
                <Routes>
                    <Route
                        path='/'
                        element={domen.length === 0 ? <Main /> : <Navigate to={`/${domen}/menu`} />}
                    />
                    <Route path='/:slug/menu' element={<Menu readOnly={domen.length !== 0} />} />
                    <Route
                        path='/:slug/menu/:catSlug'
                        element={<Menu readOnly={domen.length !== 0} />}
                    />

                    <Route
                        path='/:slug/cart'
                        element={domen.length === 0 ? <Cart /> : <Navigate to={`/${domen}/menu`} />}
                    />
                    <Route
                        path='/:slug/product/:product_id'
                        element={<Product readOnly={domen.length !== 0} />}
                    />
                    <Route
                        path='/order'
                        element={
                            domen.length === 0 ? <Order /> : <Navigate to={`/${domen}/menu`} />
                        }
                    />
                    <Route
                        path='/signin'
                        element={
                            domen.length === 0 ? <SignIn /> : <Navigate to={`/${domen}/menu`} />
                        }
                    />
                    <Route
                        path='/profile'
                        element={
                            domen.length === 0 ? <Profile /> : <Navigate to={`/${domen}/menu`} />
                        }
                    />
                    <Route
                        path='/agreement'
                        element={
                            domen.length === 0 ? <Agreement /> : <Navigate to={`/${domen}/menu`} />
                        }
                    />
                    <Route
                        path='/delivery'
                        element={
                            domen.length === 0 ? <Delivery /> : <Navigate to={`/${domen}/menu`} />
                        }
                    />
                    <Route
                        path='/thanks-for-order'
                        element={
                            domen.length === 0 ? (
                                <ThanksForOrder />
                            ) : (
                                <Navigate to={`/${domen}/menu`} />
                            )
                        }
                    />
                </Routes>
            </main>
            <Footer />
        </>
    )
}

export default App
